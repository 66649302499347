import React from "react";

const ComparisionLoading = () => {
  return (
    <div className="flex items-center justify-center w-[803px] h-[200px] bg-white p-4 rounded-2xl shadow-xl">
      <div className="animate-spin h-10 w-10 rounded-full border-4 border-primary border-t-transparent"></div>
    </div>
  );
};

export default ComparisionLoading;
